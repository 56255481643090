import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Divider, Row, Space } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useTableParams } from 'hooks/useTableParams';
import { selectTournamentRooms } from 'store/accounts';
import { selectPreferences } from 'store/preferences';
import { getTournaments, selectTournaments } from 'store/tournaments';
import { getSerialTournaments, selectSerialTournaments } from 'store/serial-tournaments';
import { TournamentsTable } from 'components/Tournaments/TournamentsTable';
import { SerialTournamentsTable } from 'components/Tournaments/SerialTournamentsTable';
import TournamentsSearch from 'components/Tournaments/TournamentsSearch';
import { LimitsModal } from './fragments/LimitsModal';

export const Tournaments = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(selectTournaments);
  const { list: serialList, loading: serialLoading } = useSelector(selectSerialTournaments);
  const myRooms = useSelector(selectTournamentRooms);
  const { tournamentRooms = [] } = useSelector(selectPreferences) ?? {};
  const { params, addFilter, handleTableChange, resetParams } = useTableParams(
    {},
    {
      persist: 3600 * 12,
      namespace: 'tournamentFilters',
    },
  );

  useEffect(() => {
    dispatch(getTournaments(params));
    dispatch(getSerialTournaments(params));
  }, [dispatch, params]);

  return (
    <>
      <Space style={{ alignItems: 'start' }}>
        <LimitsModal />
        <Divider type="vertical" />
        <Button type="primary" icon={<ReloadOutlined />} onClick={resetParams} />
        <TournamentsSearch
          values={params}
          onChange={addFilter}
          myRooms={tournamentRooms?.length ? tournamentRooms : myRooms.map((account) => account.type)}
        />
      </Space>
      <Divider />
      <Row justify="space-between" gutter={10}>
        <Col span={13}>
          <TournamentsTable
            offset={241}
            loading={loading}
            dataSource={list}
            onFilter={addFilter}
            onChange={handleTableChange}
            filterValues={params}
          />
        </Col>
        <Col span={11}>
          <SerialTournamentsTable offset={231} loading={serialLoading} dataSource={serialList} />
        </Col>
      </Row>
    </>
  );
};
