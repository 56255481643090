import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Select, Table } from 'antd';
import { toAmount } from 'utils/format';
import { useTableParams } from 'hooks/useTableParams';
import { close } from 'store/transfers';
import { selectUser } from 'store/auth';
import WithCurrency from 'components/WithCurrency';
import { DonateModal } from './DonateModal';

const rowClassName = (record) => {
  const diff = Math.abs(new Date() - new Date(record.createdAt));

  return Math.ceil(diff / (1000 * 60 * 60)) > 24 ? 'transfer-expired' : '';
};

export const TransfersTable = ({ list, loading, onChange }) => {
  const dispatch = useDispatch();
  const [account, setAccount] = useState();
  const { params, handleTableChange } = useTableParams();
  const user = useSelector(selectUser);
  const { t } = useTranslation();

  useEffect(() => {
    onChange(params);
  }, [onChange, params]);

  const dataSource = useMemo(
    () => (account ? list.docs.filter((req) => req.typeCurrency === account) : list.docs),
    [account, list],
  );

  const accountOptions = useMemo(
    () =>
      [...new Set(list.docs.map((req) => req.typeCurrency))].map((value) => ({
        key: value,
        value,
        label: value,
      })),
    [list.docs],
  );

  const columns = useMemo(
    () => [
      {
        title: (
          <Select
            placeholder={t('account')}
            options={accountOptions}
            virtual={false}
            onChange={setAccount}
            style={{ width: '100%' }}
            allowClear
          />
        ),
        dataIndex: 'account',
        key: 'account',
        width: 200,
        render: (account) => (
          <WithCurrency currency={account.currency}>
            {account.type} - {account.currency}
          </WithCurrency>
        ),
      },
      {
        title: t('amount'),
        dataIndex: 'currentAmount',
        key: 'currentAmount',
        render: (currentAmount, transfer) =>
          toAmount(transfer.requestedAmount - currentAmount, transfer.account.currency),
        sorter: true,
      },
      {
        title: t('transfers.created_at'),
        dataIndex: 'createdAt',
        key: 'date',
        defaultSortOrder: 'ascend',
        sorter: true,
        render: (createdAt) => new Date(createdAt).toLocaleString(),
      },
      {
        dataIndex: '_id',
        key: 'actions',
        render: (id, request) => {
          if (user._id === request.user._id) {
            return (
              <Popconfirm title={t('areYouSure')} onConfirm={() => dispatch(close(id))}>
                <Button type="primary" danger>
                  {t('close')}
                </Button>
              </Popconfirm>
            );
          }

          return (
            <DonateModal request={request} disabled={request?.reserve?.userId && request.reserve.userId !== user._id} />
          );
        },
      },
    ],
    [user, t, accountOptions],
  );

  return (
    <Table
      rowClassName={rowClassName}
      loading={loading}
      rowKey="_id"
      pagination={false}
      dataSource={dataSource}
      columns={columns}
      onChange={handleTableChange}
      scroll={{ y: 500, x: 'auto' }}
    />
  );
};
