export const ENTITY_TYPE = {
  PLAYING_MYSELF_REPORT: 'PLAYING_MYSELF_REPORT',
  PLAYING_MYSELF_TRANSACTION: 'PLAYING_MYSELF_TRANSACTION',
  USER_COURSE: 'USER_COURSE',
  USER_BLOCK: 'USER_BLOCK',
};

export const ACTION_TYPE = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
};
