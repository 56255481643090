import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SHARK_NETWORKS } from 'config/accounts';
import useLoading from 'hooks/useLoading';
import { useTableParams } from 'hooks/useTableParams';
import { getScreenNames } from 'store/users';
import SmartTable from 'components/Table/SmartTable';

const columns = [
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
    fixed: 'left',
    width: 250,
    align: 'center',
  },
  {
    title: 'Discord ID',
    dataIndex: 'discordId',
    key: 'discordId',
    fixed: 'left',
    width: 180,
    align: 'center',
  },
  ...SHARK_NETWORKS.map((groupId) => ({
    key: groupId,
    title: groupId,
    dataIndex: 'accounts',
    width: 250,
    align: 'center',
    render: (accounts) => {
      const acc = accounts.find((a) => a.groupId === groupId);

      if (!acc) return null;

      if (!acc.otherScreenNames) return acc.screenName;

      return [acc.screenName, ...acc.otherScreenNames].join(', ');
    },
  })),
];

const AccountScreenNames = () => {
  const dispatch = useDispatch();
  const { params, nextPage } = useTableParams({ pagination: { page: 1, pageSize: 40 } });
  const [data, setData] = useState({ totalDocs: 0, hasNextPage: false, docs: [] });
  const { execute, loading } = useLoading();

  useEffect(() => {
    execute(async () => {
      const result = await dispatch(getScreenNames(params));

      setData((prevState) => {
        return {
          ...result,
          docs: [...prevState.docs, ...(result.docs ?? [])],
        };
      });
    });
  }, [execute, dispatch, params]);

  return (
    <SmartTable
      columns={columns}
      hasNextPage={data.hasNextPage}
      dataSource={data.docs}
      loading={loading}
      onFetch={nextPage}
      scroll={{
        x: 'auto',
        y: 'calc(100vh - 151px)',
      }}
    />
  );
};

export default AccountScreenNames;
