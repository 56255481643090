import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { List, Tooltip } from 'antd';
import useLoading from 'hooks/useLoading';
import { getGroupedByCourse } from 'store/users';
import './index.css';

const renderUser = (user) => {
  const text = (
    <span>
      {user.username}{' '}
      {user.course !== user.scheduleCourse ? (
        <span>
          (<b>{user.scheduleCourse}</b>)
        </span>
      ) : null}
    </span>
  );

  return (
    <List.Item>
      <Tooltip title={text}>
        <div className="no-wrap" style={{ marginBottom: 0, textAlign: 'center', width: '100%' }}>
          {text}
        </div>
      </Tooltip>
    </List.Item>
  );
};

const renderItem = (item) => (
  <List.Item style={{ width: 250 }}>
    <List
      style={{ backgroundColor: '#fff' }}
      rowKey="_id"
      bordered
      header={
        <b
          style={{
            textAlign: 'center',
            display: 'block',
            fontSize: 16,
          }}
        >
          {item._id}
        </b>
      }
      dataSource={item.users}
      renderItem={renderUser}
    />
  </List.Item>
);

const Courses = ({ forceUpdate }) => {
  const dispatch = useDispatch();
  const { data, execute, loading } = useLoading([]);

  useEffect(() => {
    execute(() => dispatch(getGroupedByCourse()));
  }, [execute, forceUpdate]);

  return (
    <div style={{ overflowX: 'auto', height: 'calc(100vh - 256px)' }}>
      <List
        rowKey="_id"
        style={{ width: 254 * data.length }}
        loading={loading}
        grid={{ gutter: 4, columns: data.length }}
        dataSource={data}
        renderItem={renderItem}
      />
    </div>
  );
};

export default Courses;
