export const supportedNetworks = [
  {
    name: '888Poker',
    value: '888Poker',
  },
  {
    name: 'Chico',
    value: 'Chico',
  },
  {
    name: 'GGNetwork',
    value: 'GGNetwork',
  },
  {
    name: 'iPoker',
    value: 'iPoker',
  },
  {
    name: 'PartyPoker',
    value: 'PartyPoker',
  },
  {
    name: 'PokerStars',
    value: 'PokerStars',
  },
  {
    name: 'PokerStars(FR-ES-PT)',
    value: 'PokerStars(FR-ES-PT)',
  },
  {
    name: 'Winamax.fr',
    value: 'Winamax',
  },
  {
    name: 'WPN',
    value: 'WPN',
  },
  // {
  //   name: 'WPT Global',
  //   value: 'WPT Global',
  // },
];
