import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popconfirm, Space, Table, Tooltip } from 'antd';
import {
  BorderlessTableOutlined,
  DeleteOutlined,
  GlobalOutlined,
  RadarChartOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { toAmount, toOptional } from 'utils/format';
import { sort } from 'utils/account';
import { permissions } from 'config/permissions';
import { deleteAccount } from 'store/accounts';
import { can } from 'store/auth';
import { UpdateModal } from './UpdateModal';
import { CreateModal as AccountCreate } from './CreateModal';

export const List = ({
  list,
  onDeleteAccount,
  createdAccounts,
  onCreateAccount,
  onUpdateAccount,
  allowBalanceUpdate,
  interactive = true,
}) => {
  const dispatch = useDispatch();
  const hasPermission = useSelector(can);
  const canDeleteAccounts = hasPermission(permissions.CanDeleteAccounts);

  const onDelete = useCallback(
    async (id) => {
      await dispatch(deleteAccount(id));
      onDeleteAccount(id);
    },
    [onDeleteAccount],
  );

  const sortedAccounts = useMemo(() => sort(list), [list]);

  const columns = useMemo(() => {
    const cols = [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Screen Name',
        dataIndex: 'screenName',
        key: 'screenName',
        align: 'center',
        render: toOptional,
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        align: 'center',
        render: (balance, account) => toAmount(balance, account.currency),
      },
      {
        title: "Add's",
        dataIndex: 'region',
        key: 'options',
        align: 'center',
        render: (_, account) => {
          return (
            <Space>
              {!!account.region && (
                <Tooltip title={account.region}>
                  {account.region === 'RU' ? <BorderlessTableOutlined /> : <GlobalOutlined />}
                </Tooltip>
              )}
              {account.ableToTransfer && (
                <Tooltip title="Transfer available">
                  <UserSwitchOutlined />
                </Tooltip>
              )}
              {account.isScam && (
                <Tooltip title="Scam">
                  <RadarChartOutlined label="Scam" />
                </Tooltip>
              )}
            </Space>
          );
        },
      },
      {
        title: 'Transfer Address',
        dataIndex: 'transferAddress',
        key: 'transferAddress',
        align: 'center',
        render: toOptional,
      },
    ];

    if (interactive) {
      cols.push({
        title: 'Actions',
        dataIndex: '_id',
        key: 'actions',
        align: 'center',
        render: (id, account) =>
          !account.inner && (
            <Space>
              <UpdateModal
                createdAccounts={sortedAccounts}
                onUpdateAccount={onUpdateAccount}
                account={account}
                allowBalanceUpdate={allowBalanceUpdate}
              />
              {canDeleteAccounts && (
                <Popconfirm title="Are you sure?" onConfirm={() => onDelete(id)}>
                  <Button type="primary" danger icon={<DeleteOutlined />} />
                </Popconfirm>
              )}
            </Space>
          ),
      });
    }

    return cols;
  }, [onDelete, onUpdateAccount, interactive, allowBalanceUpdate, canDeleteAccounts]);

  const title = useCallback(
    () => (
      <AccountCreate
        balance={allowBalanceUpdate ? undefined : 0}
        createdAccounts={createdAccounts}
        onCreateAccount={onCreateAccount}
      />
    ),
    [createdAccounts, onCreateAccount],
  );

  return (
    <Table
      rowClassName={(acc) => !acc.active && 'disabled-row'}
      title={interactive && title}
      columns={columns}
      pagination={false}
      rowKey="_id"
      dataSource={sortedAccounts}
    />
  );
};
