import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputNumber, Select, Table, Tooltip } from 'antd';
import debounce from 'lodash.debounce';
import { toLocalTime } from 'utils/format';
import { physicalPurposes } from 'config/transactions';
import { useTableParams } from 'hooks/useTableParams';
import DateRangePicker from 'components/Form/DateRangePicker';
import ImageCell from 'components/Table/ImageCell';
import { AmountByRate, AmountByValue } from 'components/Table/AmountCell';
import WithCurrency from 'components/WithCurrency';

export const InternalTransactionsTable = ({ list, loading, onChange }) => {
  const { addFilter, params, handleTableChange } = useTableParams({ pagination: { page: 1, pageSize: 10 } });
  const { t } = useTranslation();

  useEffect(() => {
    onChange(params);
  }, [onChange, params]);

  const changeRange = useCallback(
    (current) => {
      addFilter('range')(
        current?.map((moment, idx) => {
          const date = idx === 0 ? moment.startOf('day') : moment.endOf('day');
          return date.unix() * 1000;
        }) || [],
      );
    },
    [addFilter],
  );

  const columns = useMemo(
    () => [
      {
        title: <DateRangePicker onChange={changeRange} />,
        children: [
          {
            width: 300,
            title: t('internal_transactions.createdAt'),
            dataIndex: 'createdAt',
            key: 'date',
            render: (createdAt, { currency }) => (
              <WithCurrency currency={currency}>{toLocalTime(createdAt)}</WithCurrency>
            ),
          },
        ],
      },
      {
        title: (
          <Input
            placeholder={t('internal_transactions.from_to_user')}
            onChange={debounce(addFilter('fromUsername'), 500)}
          />
        ),
        children: [
          {
            title: t('internal_transactions.from_user'),
            dataIndex: 'fromUsername',
            key: 'fromUsername',
            width: 200,
          },
          {
            title: t('internal_transactions.to_user'),
            dataIndex: 'toUsername',
            key: 'toUsername',
            width: 200,
          },
        ],
      },
      {
        title: <Input placeholder={t('account')} onChange={debounce(addFilter('toAccountName'), 500)} />,
        children: [
          {
            width: 250,
            title: t('account'),
            dataIndex: 'toAccountName',
            key: 'toAccountName',
            render: (toAccountName) => toAccountName || '-',
          },
        ],
      },
      {
        // <AmountFilter type="Internal" onChange={addFilter('amountRange')} />,
        title: (
          <Input.Group compact>
            <InputNumber
              placeholder={t('placeholder.min')}
              title={t('placeholder.min')}
              controls={false}
              style={{ width: '50%' }}
              onChange={debounce(addFilter('minAmount'), 500)}
            />
            <InputNumber
              placeholder={t('placeholder.max')}
              title={t('placeholder.max')}
              controls={false}
              style={{ width: '50%' }}
              onChange={debounce(addFilter('maxAmount'), 500)}
            />
          </Input.Group>
        ),
        children: [
          {
            width: 200,
            title: t('amount'),
            dataIndex: 'amount',
            key: 'amount',
            render: (amount, transaction) => (
              <AmountByValue amount={amount} amountUSD={transaction.amountUSD} currency={transaction.currency} />
            ),
          },
        ],
      },
      {
        title: '',
        children: [
          {
            width: 113,
            title: t('internal_transactions.commission'),
            dataIndex: 'commission',
            key: 'commission',
            render: (commission, { currency, rate }) =>
              commission === undefined ? (
                '-'
              ) : (
                <AmountByRate amount={commission} currency={currency} latestEurRate={{ ratio: rate }} />
              ),
          },
        ],
      },
      {
        title: (
          <Select
            placeholder={t('purpose')}
            options={physicalPurposes.map((purpose) => ({ ...purpose, label: t(purpose.value) }))}
            virtual={false}
            onChange={addFilter('purpose')}
            style={{ width: '100%' }}
            allowClear
          />
        ),
        children: [
          {
            width: 300,
            title: t('purpose'),
            dataIndex: 'purpose',
            key: 'purpose',
            render: t,
          },
        ],
      },
      {
        title: <Input placeholder={t('comment')} onChange={debounce(addFilter('comment'), 500)} />,
        children: [
          {
            width: 200,
            title: t('comment'),
            dataIndex: 'comment',
            key: 'comment',
            ellipsis: true,
            className: 'text-cell-ellipsis',
            render: (comment) => <Tooltip title={t(comment)}>{t(comment)}</Tooltip>,
          },
        ],
      },
      {
        title: '',
        dataIndex: '_id',
        key: 'actions',
        align: 'center',
        width: 82,
        render: (id, transaction) =>
          transaction.screenshot &&
          !transaction.cancelledBy && <ImageCell src={transaction.screenshot} tooltipPlacement="left" />,
      },
    ],
    [addFilter, changeRange, t],
  );

  return (
    <Table
      loading={loading}
      columns={columns}
      rowKey="_id"
      pagination={{ total: list.totalDocs, pageSize: list.limit, current: list.page, style: { marginBottom: 0 } }}
      dataSource={list.docs}
      onChange={handleTableChange}
      scroll={{ x: 800 }}
    />
  );
};
