import { CURRENCY_COLORS } from 'config/common';

const WithCurrency = ({ currency, children }) => (
  <div style={{ display: 'flex', alignItems: 'center', margin: -8, gap: 8 }}>
    <div className="currency-cell" style={{ background: CURRENCY_COLORS[currency] }}>
      <span className="currency-cell-value">{currency}</span>
    </div>
    {children}
  </div>
);

export default WithCurrency;
