import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Form, Modal, notification, Select, Upload } from "antd";
import { UploadOutlined, FileImageOutlined } from '@ant-design/icons';
import { selectList } from '../../../store/grid-graphics';
import { selectList as radarSelectList } from '../../../store/radar-graphics';
import { importGridGraphics } from '../../../store/grid-graphics';
import { importRadarGraphics } from '../../../store/radar-graphics';


export const ImportGraphicsModal = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isRadarSelected, setIsRadarSelected] = useState(false);
  const [form] = Form.useForm();
  const { loading } = useSelector(selectList);
  const { loading: radarLoading } = useSelector(radarSelectList);

  const onSubmit = async (values) => {
    const formData = new FormData();
    // noinspection JSUnresolvedVariable
    formData.append('zipFile', values.zipFile.file, values.zipFile.file.name);
    formData.append('csvFile', values.csvFile.file, values.csvFile.file.name);

    if (values.type === 'radar' && values.date) {
      const date = values.date;
      formData.append('month', date.month() + 1); // months in moment are zero-based
      formData.append('year', date.year());
    }

    try {
      switch (values.type) {
        case 'grid':
          await dispatch(importGridGraphics(formData));
          notification.success({ message: 'Grid archive is being processed. It will take some time to save it.' });
          break;
        case 'radar':
          await dispatch(importRadarGraphics(formData));
          notification.success({ message: 'Radar archive is being processed. It will take some time to save it.' });
          break;
        default:
          notification.error({ message: 'Unknown type ' + values.type });
          break;
      }
      setVisible(false);
    } catch (e) {
      if (e.response?.data?.errors) {
        form.setFields(
          e.response.data.errors.map((error) => {
            const key = Object.keys(error)[0];
            return {
              name: key,
              errors: [error[key]],
            };
          }),
        );
      }
    }
  };

  const handleTypeChange = (value) => {
    setIsRadarSelected(value === 'radar');

    if (value !== 'radar') {
      form.setFieldValue('date', null);
    }
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)} icon={<FileImageOutlined />}>
        Import Graphics
      </Button>
      <Modal
        confirmLoading={loading || radarLoading}
        open={visible}
        title="Import Graphics"
        onOk={form.submit}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
      >
        <Form name="serial-evaluation-archive" form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            label={'Type'}
            name="type"
            rules={[{ required: true, message: 'Please select a type of CSV file' }]}
          >
            <Select
              placeholder="Type"
              style={{ maxWidth: 120 }}
              allowClear
              onChange={handleTypeChange}
            >
              <Select.Option value="grid">Grid</Select.Option>
              <Select.Option value="radar">Radar</Select.Option>
            </Select>
          </Form.Item>

          {isRadarSelected && (
            <Form.Item
              label="Choose month and year"
              name="date"
              rules={[{ required: true, message: 'Please select month and year' }]}
            >
              <DatePicker
                picker="month"
                format="MMMM YYYY"
                placeholder="Select month and year"
              />
            </Form.Item>
          )}

          <Form.Item label="ZIP file" name="zipFile" rules={[{ required: true }]}>
            <Upload accept=".zip" maxCount={1} beforeUpload={() => false} defaultFileList={[]}>
              <Button icon={<UploadOutlined />}>Select file</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="CSV descriptor file" name="csvFile" rules={[{ required: true }]}>
            <Upload accept=".csv" maxCount={1} beforeUpload={() => false} defaultFileList={[]}>
              <Button icon={<UploadOutlined />}>Select file</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
