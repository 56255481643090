import { Space } from 'antd';
import moment from 'moment';
import DatePicker from 'components/Form/DatePicker';
import { UserSelect } from 'components/Form/UserSelect';

const CourseLogSearch = ({ values = {}, onChange }) => {
  const handleDateChange = (field) => (date) => {
    onChange(field)(date ? date.toISOString() : undefined);
  };

  return (
    <Space>
      <DatePicker
        showTime
        value={values.dateFrom ? moment(values.dateFrom) : undefined}
        format="DD.MM.YYYY HH:mm"
        placeholder='Date From'
        onChange={handleDateChange('dateFrom')}
      />
      <DatePicker
        showTime
        value={values.dateTo ? moment(values.dateTo) : undefined}
        format="DD.MM.YYYY HH:mm"
        placeholder='Date To'
        onChange={handleDateChange('dateTo')}
      />
      <UserSelect
        onChange={onChange('entityId')}
        allowClear
        value={values.entityId}
      />
    </Space>
  );
};

export default CourseLogSearch;
