import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Modal, notification, Steps } from 'antd';
import {
  AccountBookOutlined,
  CheckCircleOutlined,
  IdcardOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useToggle } from 'hooks/useToggle';
import { deleteAccounts } from 'store/accounts';
import { createUser, selectLoading } from 'store/users';
import { useAccounts } from '../../hooks';
import { List as Accounts } from '../../components/Accounts/List';
import { Confirmation } from './Confirmation';
import PersonalInfo from './PersonalInfo';
import Success from './Success';

const fields = [
  'username',
  'email',
  'password',
  'name',
  'discordId',
  'scheduleCourse',
  'course',
  'country',
  'buyInPercent',
];

const CreateModal = ({ onCreate }) => {
  const dispatch = useDispatch();
  const { active, setActive, activate } = useToggle();
  const { createdAccounts, setCreatedAccounts, onCreateAccount, onDeleteAccount, onUpdateAccount } = useAccounts();
  const [current, setCurrent] = useState(0);
  const [closeable, setCloseable] = useState(true);
  const [userForm] = Form.useForm();
  const [user, setUser] = useState(null);
  const [valid, setValid] = useState(null);
  const loading = useSelector(selectLoading);

  const steps = useMemo(
    () => [
      {
        title: 'Personal info',
        icon: <UserOutlined />,
        content: <PersonalInfo form={userForm} onChange={setValid} />,
      },
      {
        title: 'Accounts',
        icon: <AccountBookOutlined />,
        content: (
          <Accounts
            list={createdAccounts}
            createdAccounts={createdAccounts}
            onCreateAccount={onCreateAccount}
            onDeleteAccount={onDeleteAccount}
            onUpdateAccount={onUpdateAccount}
            allowBalanceUpdate
          />
        ),
      },
      {
        title: 'Confirmation',
        icon: <IdcardOutlined />,
        content: <Confirmation form={userForm} accounts={createdAccounts} />,
      },
      {
        title: 'Done',
        icon: <CheckCircleOutlined />,
        content: <Success form={userForm} onCopy={() => setCloseable(true)} />,
      },
    ],
    [userForm, createdAccounts, onCreateAccount, onDeleteAccount, onUpdateAccount],
  );

  const reset = useCallback(() => {
    userForm.resetFields();
    setCreatedAccounts([]);
    setUser(null);
    setCurrent(0);
  }, [userForm, setCreatedAccounts]);

  const close = useCallback(async () => {
    if (!closeable) {
      notification.warn({
        message: 'Please copy credentials to clipboard before closing',
      });
    } else if (!user && createdAccounts.length > 0) {
      const confirmed = window.confirm('Are you sure you want to cancel?');
      if (confirmed) {
        dispatch(deleteAccounts(createdAccounts.map(({ _id }) => _id)));
        reset();
      }
      setActive(!confirmed);
    } else {
      reset();
      setActive(false);
    }
  }, [closeable, user, reset, createdAccounts, setActive, dispatch]);

  const next = useCallback(async () => {
    switch (current) {
      case 0: {
        try {
          await userForm.validateFields(fields);
          setCurrent(current + 1);
        } catch (e) {}
        break;
      }
      case 2: {
        try {
          const values = await userForm.validateFields(fields);
          const createdUser = await dispatch(
            createUser({
              ...values,
              accounts: createdAccounts.map(({ _id }) => _id),
              username: values.username.trim(),
            }),
          );
          setUser(createdUser);
          setCloseable(false);
          setCurrent(current + 1);
          onCreate();
        } catch (e) {
          console.error(e);
        }
        break;
      }
      case 3:
        close().then();
        break;
      default:
        setCurrent(current + 1);
    }
  }, [current, createdAccounts, userForm, dispatch, close, onCreate]);

  const back = useCallback(() => setCurrent((curr) => curr - 1), []);

  const footer = [
    <Button key="back" onClick={back} disabled={!current || current === steps.length - 1}>
      Back
    </Button>,
    <Button key="next" onClick={next} loading={loading} disabled={!valid}>
      {current === steps.length - 1 ? 'Finish' : 'Next'}
    </Button>,
  ];

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={activate}>
        Create
      </Button>
      <Modal width={700} title="Create user" open={active} onCancel={close} footer={footer}>
        <Steps current={current} items={steps} style={{ marginBottom: 24 }} />
        {steps[current].content}
      </Modal>
    </>
  );
};

export default CreateModal;
