import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import debounce from 'lodash.debounce';
import { getMy } from 'store/radar-graphics';

const RadarSearch = ({ onChange, ...props }) => {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const calledOnce = useRef(false);
  const [value, setValue] = useState(undefined);

  const searchGraphics = useCallback(
    async (query = undefined) => {
      const data = await dispatch(getMy({ date: query }));
      setOptions(data);
    },
    [dispatch],
  );

  useEffect(() => {
    searchGraphics();
  }, [searchGraphics]);

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }

    if (options.length) {
      setValue(options[0]._id);
      onChange(options[0]);
    }
  }, [onChange, options]);

  const handleChange = useCallback(
    (id) => {
      setValue(id);
      onChange(options.find((opt) => opt._id === id));
    },
    [onChange, options],
  );

  return (
    <Select
      placeholder="mm.yyyy"
      showSearch
      onSearch={debounce(searchGraphics, 500)}
      filterOption={false}
      options={options.map((opt) => ({ value: opt._id, label: `${opt.month}.${opt.year}` }))}
      virtual={false}
      value={value}
      onChange={handleChange}
      style={{ width: 200 }}
      {...props}
    />
  );
};

export default RadarSearch;
