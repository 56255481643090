import { Table } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const CommonTotalTable = ({ dataSource, loading }) => {
  const { t } = useTranslation();

  const chooseClass = useCallback(
    (record) => {
      if (record.degree === record.playerName) {
        return 'underscored';
      }

      const isWarning = dataSource
        .filter((item) => item.degree === item.playerName && item.stage === record.stage)
        .some((item) => item.ev > record.ev);

      return isWarning ? 'red' : 'green';
    },
    [dataSource],
  );

  const columns = [
    {
      title: t('totals.table.player'),
      dataIndex: 'playerName',
      key: 'playerName',
      align: 'center',
      render: (playerName) => playerName || '-',
    },
    {
      title: t('totals.table.stage'),
      dataIndex: 'stage',
      key: 'stage',
      align: 'center',
      render: (value) => (value != null ? value : '-'),
    },
    {
      title: t('totals.table.hands'),
      dataIndex: 'numberOfHands',
      key: 'numberOfHands',
      align: 'center',
      render: (value) => (value != null ? value : '-'),
    },
    {
      title: t('totals.table.abi'),
      dataIndex: 'abi',
      key: 'abi',
      align: 'center',
      render: (value) => (value != null ? value : '-'),
    },
    {
      title: t('totals.table.ev'),
      dataIndex: 'ev',
      key: 'ev',
      align: 'center',
      render: (value) => (value != null ? value : '-'),
    },
    {
      title: t('totals.table.net'),
      dataIndex: 'net',
      key: 'net',
      align: 'center',
      render: (value) => (value != null ? value : '-'),
    },
    {
      title: t('totals.table.period'),
      dataIndex: 'period',
      key: 'period',
      align: 'center',
      render: (value) => (value != null ? value : '-'),
    },
  ];

  return (
    <Table
      rowKey="_id"
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      rowClassName={(record) => chooseClass(record)}
      loading={loading}
    />
  );
};
