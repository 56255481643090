import { Table, Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useMemo } from 'react';
import { ENTITY_TYPE } from "../../config/actions";
import './index.css';

const CourseLogTable = ({
    data,
    loading,
    onPageChange,
    onDelete,
    canDelete,
    offset = 300
  }) => {
  const columns = useMemo(() => [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('DD.MM.YYYY HH:mm'),
      width: 180,
      align: 'center',
    },
    {
      title: 'Username',
      dataIndex: 'content',
      key: 'username',
      render: (content) => content?.username || '-',
      align: 'center',
    },
    {
      title: 'Course',
      dataIndex: 'content',
      key: 'course',
      render: (content, record) => {
        if (record.entityType === ENTITY_TYPE.USER_COURSE) {
          return content?.course || '-';
        }
        if (record.entityType === ENTITY_TYPE.USER_BLOCK) {
          return content?.isBlocked ? 'Blocked' : 'Unblocked';
        }
        return '-';
      },
      align: 'center',
    },
    {
      title: 'Schedule Course',
      dataIndex: 'content',
      key: 'scheduleCourse',
      render: (content, record) => {
        if (record.entityType === ENTITY_TYPE.USER_COURSE) {
          return content?.scheduleCourse || '-';
        }
        return '-';
      },
      align: 'center',
    },
    {
      title: 'Updated By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (user) => user?.username || '-',
      align: 'center',
    },
    ...(canDelete ? [{
      title: 'Actions',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, record) => (
        <Popconfirm
          title="Are you sure you want to delete this action?"
          onConfirm={() => onDelete(record._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      ),
    }] : []),
  ], [canDelete, onDelete]);

  const calculateRowClassName = (record) => {
    if (record.entityType === ENTITY_TYPE.USER_BLOCK) {
      return record.content?.isBlocked ? 'red' : 'blue';
    }
    return '';
  };

  return (
    <Table
      columns={columns}
      dataSource={data.docs}
      rowKey="_id"
      loading={loading}
      scroll={{ y: `calc(100vh - ${offset}px)` }}
      rowClassName={calculateRowClassName}
      pagination={{
        current: data.page,
        pageSize: data.limit,
        total: data.totalDocs,
        onChange: onPageChange,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} items`,
      }}
    />
  );
};

export default CourseLogTable;
