import { useCallback, useEffect, useState } from "react";
import { Space, Card, message } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import CourseLogSearch from "components/CourseLog/CourseLogSearch";
import { deleteAction, searchActions, selectActions } from "store/actions";
import CourseLogTable from "../../../components/CourseLog/CourseLogTable";
import { ACTION_TYPE, ENTITY_TYPE } from "../../../config/actions";
import { can } from "../../../store/auth";
import { permissions } from 'config/permissions';

export const CourseLog = () => {
  const dispatch = useDispatch();
  const hasPermission = useSelector(can);

  const { searchList, loading } = useSelector(selectActions);
  const [searchParams, setSearchParams] = useState({
    dateFrom: undefined,
    dateTo: undefined,
    entityId: undefined,
    page: 1,
    limit: 40,
  });

  const canDeleteLogs = hasPermission(permissions.CanDeleteCourseLog);

  useEffect(() => {
    const params = {
      ...searchParams,
      entityTypes: [ENTITY_TYPE.USER_COURSE, ENTITY_TYPE.USER_BLOCK],
      actionType: ACTION_TYPE.UPDATE,
    };

    dispatch(searchActions(params));
  }, [searchParams, dispatch]);

  const handleSearchChange = (field) => (value) => {
    setSearchParams(prev => ({
      ...prev,
      page: 1,
      [field]: value,
    }));
  };

  const handlePageChange = (page) => {
    setSearchParams(prev => ({
      ...prev,
      page,
    }));
  };

  const handleDelete = useCallback(async (id) => {
    if (!canDeleteLogs) return;

    try {
      await dispatch(deleteAction(id));
      message.success('Action deleted successfully');
    } catch (error) {
      message.error('Failed to delete action');
    }
  }, [dispatch, canDeleteLogs]);

  return (
    <Card>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <CourseLogSearch
          values={searchParams}
          onChange={handleSearchChange}
        />
        <CourseLogTable
          data={searchList}
          loading={loading}
          onPageChange={handlePageChange}
          onDelete={handleDelete}
          canDelete={canDeleteLogs}
        />
      </Space>
    </Card>
  );
};
