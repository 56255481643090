import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'radarGraphics',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    radarGraphicsRequestStarted: (radarGraphics) => {
      radarGraphics.loading = true;
    },
    radarGraphicsRequestFailed: (radarGraphics, action) => {
      radarGraphics.error = action.payload.data;
    },
    radarGraphicsRequestEnded: (radarGraphics) => {
      radarGraphics.loading = false;
    },
    radarGraphicsListReceived: (radarGraphics, action) => {
      radarGraphics.list = action.payload.data;
    },
  },
});

export const {
  radarGraphicsRequestStarted,
  radarGraphicsRequestFailed,
  radarGraphicsRequestEnded,
  radarGraphicsListReceived,
} = slice.actions;
export default slice.reducer;

//api calls
export const getAll = (params) =>
  apiCallBegan({
    url: `/radar-graphics`,
    params,
    onStart: radarGraphicsRequestStarted,
    onSuccess: radarGraphicsListReceived,
    onError: radarGraphicsRequestFailed,
    onEnd: radarGraphicsRequestEnded,
  });

export const getMy = (params = {}) => {
  return apiCallBegan({
    url: `/radar-graphics/my`,
    params,
    onStart: radarGraphicsRequestStarted,
    onSuccess: radarGraphicsListReceived,
    onError: radarGraphicsRequestFailed,
    onEnd: radarGraphicsRequestEnded,
  });
};

export const importRadarGraphics = (data) =>
  apiCallBegan({
    url: `/radar-graphics`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: radarGraphicsRequestStarted,
    onEnd: radarGraphicsRequestEnded,
  });

//selector
export const selectList = createSelector(
  (state) => state.radarGraphics,
  (radarGraphics) => ({
    list: radarGraphics.list,
    loading: radarGraphics.loading,
  }),
);
