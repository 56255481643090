import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Image, Row, Tabs } from 'antd';
import { getMy, selectList } from 'store/totals';
import { getMy as getMyAbi, selectList as selectAbiList } from 'store/totals-abi';
import { getMy as getMyGrid, selectList as selectGridList } from 'store/grid-graphics';
import { CommonTotalTable } from 'components/Totals/CommonTotalTable';
import { TotalABITable } from 'components/Totals/TotalABITable';
import RadarSearch from './RadarSearch';

export const Totals = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('totals');
  const [selectedRadarGraphic, setSelectedRadarGraphic] = useState(undefined);
  const { list, loading } = useSelector(selectList);
  const { list: listAbi, loading: loadingAbi } = useSelector(selectAbiList);
  const { list: listGridGraphics, loading: loadingGridGraphics } = useSelector(selectGridList);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getMy());
    dispatch(getMyAbi());
    dispatch(getMyGrid());
  }, [dispatch, activeTab]);

  return (
    <Tabs
      type="card"
      defaultActiveKey="totals"
      activeKey={activeTab}
      onChange={setActiveTab}
      tabBarExtraContent={activeTab === 'radar' && <RadarSearch onChange={setSelectedRadarGraphic} />}
    >
      <Tabs.TabPane tab={t('totals.tabs.totals')} key="totals">
        <Row justify="space-between" gutter={10}>
          <Col span={11}>
            <CommonTotalTable dataSource={list} loading={loading} />
          </Col>
          <Col span={13}>
            <TotalABITable dataSource={listAbi} loading={loadingAbi} />
          </Col>
        </Row>
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('totals.tabs.grid')} key="grid">
        {listGridGraphics?.length > 0 && (
          <Image src={listGridGraphics[0].url} preview={false} loading={loadingGridGraphics} />
        )}
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('totals.tabs.radar')} key="radar">
        {selectedRadarGraphic && <Image src={selectedRadarGraphic?.url} preview={false} />}
      </Tabs.TabPane>
    </Tabs>
  );
};
