export const ACCOUNT_REGIONS = {
  Skrill: {
    label: 'Skrill',
    statuses: ['Skriller', 'True', 'Silver', 'Gold+'],
  },
  Neteller: {
    label: 'Neteller',
    statuses: ['Standart', 'True', 'Silver', 'Gold+'],
  },
};

export const PURSES = new Set(['Skrill', 'Neteller', 'Luxon']);

export const SHARK_NETWORKS = [
  'PokerStars',
  'PokerStars(FR-ES-PT)',
  'Winamax.fr',
  '888Poker',
  'GGNetwork',
  'WPN',
  'iPoker',
  'Chico',
];
