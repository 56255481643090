import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input, Table, Tooltip } from 'antd';
import debounce from 'lodash.debounce';
import { toLocalTime } from 'utils/format';
import { selectUser } from 'store/auth';
import { TYPES } from './config';
import { calculateRowClassName } from './utils';
import './index.css';

export const TournamentsTable = ({
  dataSource,
  loading,
  onChange,
  onFilter,
  adminCourse = null,
  additionalColumns = [],
  hiddenColumns = [],
  filterValues = {},
  offset = 300,
}) => {
  const { t } = useTranslation();
  const { scheduleCourse: playerCourse } = useSelector(selectUser);

  const columns = useMemo(
    () =>
      [
        {
          title: t('tournaments.startDate'),
          dataIndex: 'startDate',
          key: 'startDate',
          render: (date) => toLocalTime(date, { day: '2-digit', month: '2-digit', year: '2-digit' }),
          sortDirections: ['ascend'],
          width: 93,
          sorter: (a, b) => {
            const aDate = new Date(a.startDate);
            const bDate = new Date(b.startDate);
            return aDate - bDate;
          },
        },
        {
          title: t('tournaments.day'),
          dataIndex: 'startDate',
          key: 'weekDay',
          width: 67,
          align: 'center',
          render: (date) => toLocalTime(date, { weekday: 'short' }),
        },
        {
          title: t('tournaments.startTime'),
          dataIndex: 'startDate',
          key: 'startTime',
          width: 76,
          render: (date) => toLocalTime(date, { hour: '2-digit', minute: '2-digit' }),
        },
        {
          title: t('tournaments.room'),
          dataIndex: 'room',
          key: 'room',
          width: 70,
        },
        {
          title: (
            <Input
              allowClear
              value={filterValues.name}
              placeholder={t('tournaments.name')}
              onChange={debounce(onFilter('name'), 500)}
            />
          ),
          dataIndex: 'name',
          key: 'name',
          width: window.innerWidth < 1920 ? 200 : undefined,
          render: (name) => (
            <Tooltip title={name}>
              <div className="no-wrap">{name}</div>
            </Tooltip>
          ),
        },
        {
          title: t('tournaments.buyIn'),
          dataIndex: 'buyIn',
          key: 'buyIn',
          width: 70,
          sorter: (a, b) => a.buyIn - b.buyIn,
        },
        {
          title: t('tournaments.type'),
          dataIndex: 'type',
          key: 'type',
          width: 58,
          render: (type) => {
            const content = TYPES.find((t) => t.value === +type)?.label;

            return <Tooltip title={content}>{type}</Tooltip>;
          },
        },
        {
          title: t('tournaments.endTime'),
          dataIndex: 'endDate',
          key: 'endTime',
          width: 152,
          render: (date) => toLocalTime(date, { hour: '2-digit', minute: '2-digit' }),
        },
        ...additionalColumns,
      ].filter((col) => !hiddenColumns.includes(col.key)),
    [onFilter, additionalColumns, t, adminCourse, playerCourse],
  );

  return (
    <Table
      rowKey="_id"
      className="tournaments-table"
      scroll={{ y: `calc(100vh - ${offset}px)` }}
      rowClassName={(tournament) => calculateRowClassName(tournament, adminCourse || playerCourse)}
      onChange={onChange}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      loading={loading}
    />
  );
};
