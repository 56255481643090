import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Input, InputNumber, Popconfirm, Space, Table, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { toLocalTime, toOptional } from 'utils/format';
import { getMyActivePackage, selectMyActivePackage } from 'store/packages';
import { createRevertPersonalTransaction, selectPersonalTransactions } from 'store/personal-transactions';
import { InfiniteScroll } from 'components/InfiniteScroll';
import ImageCell from 'components/Table/ImageCell';
import { AmountByValue } from 'components/Table/AmountCell';
import DateRangePicker from 'components/Form/DateRangePicker';
import { isRevertDisabled } from './utils';

const MyPersonalTransactionsTable = ({ onFilter, next, onRevert }) => {
  const dispatch = useDispatch();
  const { loading, list } = useSelector(selectPersonalTransactions);
  const { myActive: activePackage } = useSelector(selectMyActivePackage);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getMyActivePackage());
  }, [dispatch]);

  const changeRange = useCallback(
    (current) => {
      onFilter('range')(
        current?.map((moment, idx) => {
          const date = idx === 0 ? moment.startOf('day') : moment.endOf('day');
          return date.unix() * 1000;
        }) || [],
      );
    },
    [onFilter],
  );

  const revertTransaction = useCallback(
    async (id) => {
      await dispatch(createRevertPersonalTransaction(id));
      onRevert();
    },
    [dispatch, onRevert],
  );

  const columns = useMemo(
    () => [
      {
        title: <Input placeholder={t('account')} onChange={debounce(onFilter('toAccountName'), 500)} />,
        dataIndex: 'toAccountName',
        key: 'toAccountName',
        render: toOptional,
        width: 170,
      },
      {
        title: (
          <Input.Group compact>
            <InputNumber
              placeholder={t('minAmount')}
              title={t('minAmount')}
              controls={false}
              onChange={debounce(onFilter('minAmount'), 500)}
              style={{ width: 120 }}
            />
            <InputNumber
              placeholder={t('maxAmount')}
              title={t('maxAmount')}
              controls={false}
              onChange={debounce(onFilter('maxAmount'), 500)}
              style={{ width: 120 }}
            />
          </Input.Group>
        ),
        width: 220,
        dataIndex: 'amount',
        key: 'amount',
        render: (amount, transaction) => (
          <AmountByValue amount={amount} amountUSD={transaction.amountUSD} currency={transaction.currency} />
        ),
      },
      {
        title: <DateRangePicker onChange={changeRange} />,
        dataIndex: 'createdAt',
        key: 'date',
        width: 240,
        render: toLocalTime,
      },
      {
        title: t('purpose'),
        dataIndex: 'purpose',
        key: 'purpose',
        width: 200,
        render: t,
      },
      {
        title: t('comment'),
        dataIndex: 'comment',
        key: 'comment',
        className: 'text-cell',
        render: toOptional,
      },
      {
        dataIndex: '_id',
        key: 'actions',
        align: 'center',
        width: 106,
        render: (id, transaction) => (
          <Space>
            {transaction.screenshot && !transaction.cancelledBy && <ImageCell src={transaction.screenshot} />}
            {!isRevertDisabled(transaction, activePackage) && (
              <Tooltip title={t('delete')}>
                <Popconfirm title={t('areYouSure')} onConfirm={() => revertTransaction(id)}>
                  <Button type="primary" danger icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
            )}
          </Space>
        ),
      },
    ],
    [onFilter, t],
  );

  return (
    <InfiniteScroll dataLength={list.docs.length} hasMore={list.hasNextPage} next={next}>
      <Table
        loading={loading}
        rowKey="_id"
        pagination={false}
        dataSource={list.docs}
        columns={columns}
        className="strikeout-table"
        rowClassName={(transaction) => transaction.cancelledBy && 'strikeout'}
      />
    </InfiniteScroll>
  );
};

export default MyPersonalTransactionsTable;
