import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Row, Space, Tabs, Image } from 'antd';
import { getAll, selectList } from 'store/totals';
import { getAll as getAllAbi, selectList as selectAbiList } from 'store/totals-abi';
import { getAll as getGridGraphics, selectList as selectGridGraphics } from 'store/grid-graphics';
import { ImportTotalsModal } from 'components/Admin/Totals/ImportTotalsModal';
import { CommonTotalTable } from 'components/Totals/CommonTotalTable';
import { TotalABITable } from 'components/Totals/TotalABITable';
import { ImportGraphicsModal } from 'components/Admin/Totals/ImportGrahicsModal';
import { UserSelect } from 'components/Form/UserSelect';
import { ImportImagesModal } from 'components/Admin/Totals/ImportImagesModal';
import RadarSearch from './RadarSearch';

export const Totals = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const [activeTab, setActiveTab] = useState('totals');
  const [selectedRadarGraphic, setSelectedRadarGraphic] = useState(undefined);
  const { list, loading } = useSelector(selectList);
  const { list: listAbi, loading: loadingAbi } = useSelector(selectAbiList);
  const { list: listGridGraphics, loading: loadingGridGraphics } = useSelector(selectGridGraphics);
  const { t } = useTranslation();

  const setPlayerName = useCallback((playerName) => {
    updateFilter({ playerName });
  }, []);

  const updateFilter = useCallback((payload) => {
    setFilter((current) => ({ ...current, ...payload }));
  }, []);

  useEffect(() => {
    if (filter.playerName) {
      dispatch(getAll(filter));
      dispatch(getAllAbi(filter));
      dispatch(getGridGraphics(filter));
    }
  }, [filter, dispatch]);

  return (
    <>
      <Space>
        <ImportTotalsModal />
        <ImportGraphicsModal />
        <UserSelect allowClear onChange={setPlayerName} by="username" />
      </Space>
      <Space style={{ float: 'right' }}>
        <ImportImagesModal />
      </Space>
      <Divider />
      <Tabs
        type="card"
        defaultActiveKey="totals"
        activeKey={activeTab}
        onChange={setActiveTab}
        tabBarExtraContent={
          activeTab === 'radar' &&
          filter.playerName && <RadarSearch playerName={filter.playerName} onChange={setSelectedRadarGraphic} />
        }
      >
        <Tabs.TabPane tab={t('totals.tabs.totals')} key="totals">
          <Row justify="space-between" gutter={10}>
            <Col span={11}>
              <CommonTotalTable dataSource={list} loading={loading} />
            </Col>
            <Col span={13}>
              <TotalABITable dataSource={listAbi} loading={loadingAbi} />
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('totals.tabs.grid')} key="grid">
          {listGridGraphics?.length > 0 && (
            <Image src={listGridGraphics[0].url} preview={false} loading={loadingGridGraphics} />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('totals.tabs.radar')} key="radar">
          {selectedRadarGraphic && <Image src={selectedRadarGraphic?.url} preview={false} />}
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};
