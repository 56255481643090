import { useSelector } from 'react-redux';
import { Button, Divider, Space, Tabs } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { permissions } from 'config/permissions';
import { can } from 'store/auth';
import CreateModal from './fragments/CreateModal/CreateModal';
import CreateAdminModal from './fragments/CreateAdminModal/CreateAdminModal';
import List from './fragments/List';
import Courses from './fragments/Courses';
import { useForceUpdate } from './hooks';
import { useMemo } from 'react';

const downloadTimezones = () => window.open(`${process.env.REACT_APP_BASE_URL}/users/export-timezones`);

export const Users = () => {
  const hasPermission = useSelector(can);
  const forceUpdate = useForceUpdate();

  const tabs = useMemo(
    () => [
      {
        key: '1',
        label: 'List',
        children: <List forceUpdate={forceUpdate} />,
      },
      {
        key: '2',
        label: 'Courses',
        children: <Courses forceUpdate={forceUpdate} />,
      },
    ],
    [forceUpdate],
  );

  return (
    <>
      <Space>
        {hasPermission(permissions.CanManageUsers) && <CreateModal onCreate={forceUpdate} />}
        {hasPermission(permissions.CanManageAdmins) && <CreateAdminModal onCreate={forceUpdate} />}
        <Button type="primary" icon={<DownloadOutlined />} onClick={downloadTimezones}>
          Download last timezones
        </Button>
      </Space>
      <Divider />
      <Tabs destroyInactiveTabPane defaultActiveKey="1" items={tabs} />
    </>
  );
};

export default Users;
