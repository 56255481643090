import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DatePicker, Input, InputNumber, Select, Table, Tooltip } from 'antd';
import debounce from 'lodash.debounce';
import { adminPersonalPurposes } from 'config/transactions';
import { toAmount, toLocalTime, toOptional } from 'utils/format';
import { selectPersonalTransactions } from 'store/personal-transactions';
import { InfiniteScroll } from 'components/InfiniteScroll';
import ImageCell from 'components/Table/ImageCell';
import { AmountByValue } from 'components/Table/AmountCell';

const PersonalTransactionsTable = ({ onFilter, next }) => {
  const { loading, list } = useSelector(selectPersonalTransactions);

  const changeRange = useCallback(
    (current) => {
      onFilter('range')(
        current?.map((moment, idx) => {
          const date = idx === 0 ? moment.startOf('day') : moment.endOf('day');
          return date.unix() * 1000;
        }) || [],
      );
    },
    [onFilter],
  );

  const columns = useMemo(
    () => [
      {
        title: <Input placeholder="Player" onChange={debounce(onFilter('fromUsername'), 500)} />,
        dataIndex: 'fromUsername',
        key: 'fromUsername',
        width: 200,
      },
      {
        title: <Input placeholder="To Account" onChange={debounce(onFilter('toAccountName'), 500)} />,
        dataIndex: 'toAccountName',
        key: 'toAccountName',
        render: (toAccountName) => toAccountName || '-',
        width: 170,
      },
      {
        title: (
          <Input.Group compact>
            <InputNumber
              placeholder="Min amount"
              title="Min amount"
              controls={false}
              onChange={debounce(onFilter('minAmount'), 500)}
              style={{ width: 120 }}
            />
            <InputNumber
              placeholder="Max amount"
              title="Min amount"
              controls={false}
              onChange={debounce(onFilter('maxAmount'), 500)}
              style={{ width: 120 }}
            />
          </Input.Group>
        ),
        width: 200,
        dataIndex: 'amount',
        key: 'amount',
        render: (amount, transaction) => (
          <AmountByValue amount={amount} amountUSD={transaction.amountUSD} currency={transaction.currency} />
        ),
      },
      {
        title: 'Personal money',
        dataIndex: 'personalMoney',
        key: 'personalMoney',
        width: 137,
        render: (personalMoney) => toAmount(personalMoney),
      },
      {
        title: <DatePicker.RangePicker onChange={changeRange} />,
        dataIndex: 'createdAt',
        key: 'date',
        width: 240,
        render: toLocalTime,
      },
      {
        title: (
          <Select
            placeholder="Purpose"
            options={adminPersonalPurposes}
            virtual={false}
            onChange={onFilter('purpose')}
            style={{ width: '100%' }}
            allowClear
          />
        ),
        dataIndex: 'purpose',
        key: 'purpose',
        width: 200,
      },
      {
        title: <Input placeholder="Comment" onChange={debounce(onFilter('comment'), 500)} />,
        dataIndex: 'comment',
        key: 'comment',
        ellipsis: true,
        width: 200,
        className: 'text-cell-ellipsis',
        render: (comment) => <Tooltip title={comment}>{comment}</Tooltip>,
      },
      {
        title: 'Created By',
        dataIndex: 'createdBy',
        width: 200,
        render: (createdBy) => toOptional(createdBy?.username),
      },
      {
        title: '',
        dataIndex: '_id',
        key: 'actions',
        align: 'center',
        width: 106,
        render: (id, transaction) =>
          transaction.screenshot && !transaction.cancelledBy && <ImageCell src={transaction.screenshot} />,
      },
    ],
    [onFilter],
  );

  return (
    <InfiniteScroll dataLength={list.docs.length} hasMore={list.hasNextPage} next={next}>
      <Table
        loading={loading}
        rowKey="_id"
        pagination={false}
        dataSource={list.docs}
        columns={columns}
        className="strikeout-table"
        rowClassName={(transaction) => transaction.cancelledBy && 'strikeout'}
        scroll={{ x: 'auto' }}
      />
    </InfiniteScroll>
  );
};

export default PersonalTransactionsTable;
