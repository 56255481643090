import { useCallback, useState } from 'react';
import { sort } from 'utils/account';
import { getDegree } from 'utils/users';

export const useAccounts = () => {
  const [createdAccounts, setCreatedAccounts] = useState([]);

  const onCreateAccount = useCallback((account) => {
    setCreatedAccounts((current) => sort([...current, account]));
  }, []);

  const onUpdateAccount = useCallback((account) => {
    setCreatedAccounts((current) =>
      current.map((acc) => {
        if (acc._id === account._id) {
          return account;
        }

        return acc;
      }),
    );
  }, []);

  const onDeleteAccount = useCallback((id) => {
    setCreatedAccounts((current) => current.filter((account) => account._id !== id));
  }, []);

  return {
    createdAccounts,
    setCreatedAccounts,
    onCreateAccount,
    onUpdateAccount,
    onDeleteAccount,
  };
};

export const useDegree = (onChange) => {
  const [degree, setDegree] = useState('');

  const onCourseChange = useCallback(
    (value) => {
      let valid = true;

      if (value < 1 || value > 15) {
        valid = false;
      }

      setDegree(getDegree(value));
      onChange(valid, value);
    },
    [onChange],
  );

  return {
    degree,
    setDegree,
    onCourseChange,
  };
};

export const useForceUpdate = () => {
  const [_, setValue] = useState(0);

  return () => setValue((value) => value + 1);
};
