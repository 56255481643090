import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'actions',
  initialState: {
    list: [],
    searchList: {
      docs: [],
      totalDocs: 0,
      limit: 40,
      totalPages: 0,
      page: 1,
      pagingCounter: 1,
      hasPrevPage: false,
      hasNextPage: false,
    },
    lastAction: null,
    loading: false,
    error: null,
  },
  reducers: {
    actionsRequestStarted: (actions) => {
      actions.loading = true;
      actions.error = null;
    },
    actionsRequestFailed: (actions, action) => {
      actions.error = action.payload.data;
    },
    actionsRequestEnded: (actions) => {
      actions.loading = false;
    },
    actionsListReceived: (actions, action) => {
      actions.list = action.payload.data;
    },
    lastActionReceived: (actions, action) => {
      actions.lastAction = action.payload.data;
    },
    actionCreated: (actions, action) => {
      actions.list.push(action.payload.data);
    },
    searchListReceived: (actions, action) => {
      actions.searchList = action.payload.data;
    },
    actionDeleted: (actions, action) => {
      actions.list = actions.list.filter(item => item._id !== action.payload.data._id);
      actions.searchList.docs = actions.searchList.docs.filter(
        item => item._id !== action.payload.data._id
      );
      actions.searchList.totalDocs -= 1;
    },
  },
});

export const {
  actionsRequestStarted,
  actionsRequestFailed,
  actionsRequestEnded,
  actionsListReceived,
  lastActionReceived,
  actionCreated,
  searchListReceived,
  actionDeleted,
} = slice.actions;

export default slice.reducer;

// API calls
export const fetchActions = (entityId, entityType) =>
  apiCallBegan({
    url: `/actions`,
    params: { entityType, entityId },
    onStart: actionsRequestStarted,
    onSuccess: actionsListReceived,
    onError: actionsRequestFailed,
    onEnd: actionsRequestEnded,
  });

export const fetchLastAction = (entityType, entityId) =>
  apiCallBegan({
    url: `/actions/last`,
    params: { entityType, entityId },
    onStart: actionsRequestStarted,
    onSuccess: lastActionReceived,
    onError: actionsRequestFailed,
    onEnd: actionsRequestEnded,
  });

export const createAction = (data) =>
  apiCallBegan({
    url: `/actions`,
    method: 'POST',
    data,
    onStart: actionsRequestStarted,
    onSuccess: actionCreated,
    onError: actionsRequestFailed,
    onEnd: actionsRequestEnded,
  });

export const searchActions = (params) =>
  apiCallBegan({
    url: '/actions/search',
    params: {
      ...params,
      entityTypes: Array.isArray(params.entityTypes)
        ? params.entityTypes.join(',')
        : params.entityTypes,
    },
    onStart: actionsRequestStarted,
    onSuccess: searchListReceived,
    onError: actionsRequestFailed,
    onEnd: actionsRequestEnded,
  });

export const deleteAction = (id) =>
  apiCallBegan({
    url: `/actions/${id}`,
    method: 'DELETE',
    onStart: actionsRequestStarted,
    onSuccess: actionDeleted,
    onError: actionsRequestFailed,
    onEnd: actionsRequestEnded,
  });

// Selectors
export const selectActions = createSelector(
  (state) => state.actions,
  (actions) => ({
    list: actions.list,
    searchList: actions.searchList,
    lastAction: actions.lastAction,
    loading: actions.loading,
    error: actions.error,
  }),
);


