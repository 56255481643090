import { useCallback, useState } from 'react';
import moment from 'moment';
import { getItem, setItem } from 'utils/local-storage';

export const DEFAULT_PAGINATION = { page: 1, pageSize: 10 };

const DEFAULT_PARAMS = { pagination: DEFAULT_PAGINATION };

const defaultOptions = {
  persist: 0,
  namespace: 'tableParams',
};

export const useTableParams = (defaultParams = DEFAULT_PARAMS, options = defaultOptions) => {
  const [params, setParams] = useState(() => {
    if (options.persist) {
      const data = getItem(options.namespace) ?? {};
      return { ...defaultParams, ...data };
    }

    return defaultParams;
  });

  const addParams = useCallback(
    (payload) => {
      const updatedParams = { ...params, ...payload };
      setParams(updatedParams);
      if (options.persist) {
        setItem(options.namespace, updatedParams, options.persist * 1000);
      }
    },
    [params, options],
  );

  const nextPage = useCallback(() => {
    setParams((current) => ({
      ...current,
      pagination: {
        ...current.pagination,
        page: current.pagination.page + 1,
      },
    }));
  }, []);

  const addFilter = useCallback(
    (key) => (source) => {
      let value;
      switch (true) {
        case source instanceof moment:
          value = source.unix() * 1000;
          break;
        case Array.isArray(source):
          value = source.map((val, idx) => {
            if (val instanceof moment) {
              const date = idx === 0 ? val.startOf('day') : val.endOf('day');
              return date.unix() * 1000;
            }

            return val;
          });
          break;
        case !!source?.target:
          value = source.target.value;
          break;
        default:
          value = source;
      }

      addParams({
        pagination: defaultParams.pagination,
        [key]: value,
      });
    },
    [addParams, defaultParams],
  );

  const handleTableChange = useCallback(
    (pagination, filters, sort) => {
      if (Object.keys(filters || {}).length) {
        return addParams({
          ...filters,
          pagination: params.pagination,
        });
      }

      const payload = {};

      if (pagination?.current) {
        payload.pagination = { page: pagination.current, pageSize: pagination.pageSize };
      }

      if (sort?.field) {
        payload.sort = { [sort.field]: sort.order === 'ascend' ? 1 : -1 };
      }

      addParams(payload);
    },
    [params, addParams],
  );

  const resetParams = useCallback(() => {
    setParams({ ...defaultParams });
    if (options.persist) {
      localStorage.removeItem(options.namespace);
    }
  }, [defaultParams, options]);

  return {
    params,
    setParams,
    addParams,
    resetParams,
    nextPage,
    addFilter,
    handleTableChange,
  };
};
