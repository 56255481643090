export const getItem = (key) => {
  let result = JSON.parse(localStorage.getItem(key));

  if (result) {
    if (result.expireTime <= Date.now()) {
      localStorage.removeItem(key);
      return null;
    }

    // else return the data.
    return result.data;
  }

  //if there is no data provided the key, return null.
  return null;
};

export const setItem = (key, value, maxAge = 30 * 30 * 60 * 1000) => {
  // Storing the value in object.
  let result = {
    data: value,
  };

  if (maxAge) {
    /*
        setting the expireTime currentTime + expiry Time
        provided when method was called.
    */
    result.expireTime = Date.now() + maxAge;
  }

  localStorage.setItem(key, JSON.stringify(result));
};
