import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tooltip } from 'antd';
import { toLocalTime } from 'utils/format';
import { TYPES } from './config';

export const SerialTournamentsTable = ({ additionalColumns = [], dataSource, loading, offset = 300 }) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        title: t('tournaments.startDate'),
        dataIndex: 'startDate',
        key: 'startDate',
        render: (date) => toLocalTime(date, { day: '2-digit', month: '2-digit', year: '2-digit' }),
        width: 110,
      },
      {
        title: t('tournaments.startTime'),
        dataIndex: 'startDate',
        key: 'startTime',
        render: (date) => toLocalTime(date, { hour: '2-digit', minute: '2-digit' }),
        width: 75,
      },
      {
        title: t('tournaments.room'),
        dataIndex: 'room',
        key: 'room',
        width: 70,
      },
      {
        title: t('tournaments.name'),
        dataIndex: 'name',
        key: 'name',
        width: window.innerWidth < 1920 ? 200 : undefined,
        render: (name) => (
          <Tooltip title={name}>
            <div className="no-wrap">{name}</div>
          </Tooltip>
        ),
      },
      {
        title: t('tournaments.buyIn'),
        dataIndex: 'buyIn',
        key: 'buyIn',
        width: 77,
      },
      {
        title: t('tournaments.type'),
        dataIndex: 'type',
        key: 'type',
        width: 70,
        render: (type) => {
          const content = TYPES.find((t) => t.value === +type)?.label;

          return (
            <Tooltip title={content} style={{ cursor: 'pointer' }}>
              {type}
            </Tooltip>
          );
        },
      },
      ...additionalColumns,
    ],
    [t],
  );

  return (
    <Table
      rowKey="_id"
      className="tournaments-table"
      rowClassName={(tournament) => `color-${tournament.quality}`}
      scroll={{ y: `calc(100vh - ${offset}px)` }}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      loading={loading}
    />
  );
};
